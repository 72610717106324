import { graphql, Link } from "gatsby"
import {
  getImage,
  StaticImage,
  withArtDirection
} from "gatsby-plugin-image"
// import LocomotiveScroll from "locomotive-scroll"
import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  {
    nsbTall: file(relativePath: { eq: "portfolio/nsb/nsb-portrait.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    nsbWide: file(relativePath: { eq: "portfolio/nsb/nsb-landscape.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    tvpTall: file(relativePath: { eq: "portfolio/tvp/tvp-tall.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    tvpWide: file(relativePath: { eq: "portfolio/tvp/tvp-wide.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    tfiTall: file(relativePath: { eq: "portfolio/tfi/tfi-tall.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    tfiWide: file(relativePath: { eq: "portfolio/tfi/tfi-wide.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const NSB = withArtDirection(getImage(data?.nsbTall), [
    {
      media: `(min-width: 576px)`,
      image: getImage(data?.nsbWide),
    },
  ])
  const TVP = withArtDirection(getImage(data?.tvpTall), [
    {
      media: `(min-width: 576px)`,
      image: getImage(data?.tvpWide),
    },
  ])
  const TFI = withArtDirection(getImage(data?.tfiTall), [
    {
      media: `(min-width: 576px)`,
      image: getImage(data?.tfiWide),
    },
  ])

  return (
    <Layout>
      <SEO title="Product Design &amp; Development Agency" />
      <header className="bg-white" data-scroll-section>
        <div className="container min-h-[100vh] flex flex-col">
          <h1 className="font-serif text-4xl md:text-7xl max-w-[15ch] font-bold md:mt-auto mt-40 mb-2">
            We craft meaningful digital experiences
            <span className="text-bold-500">.</span>
          </h1>
          <p className="prose md:prose-xl mb-8 max-w-prose">
            Hi. We are Motionwares. We are Designers, Developers, Data
            Engineers, and Product Experts helping businesses and organizations
            thrive.
          </p>
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-6 mb-24 md:mb-auto">
            <Link
              to="#contact"
              className="inline-block text-center font-semibold relative text-white bg-black hover:text-black border-2 border-black px-6 py-3 before:transition-all before:ease-in-out before:h-full before:w-full before:transform before:top-0 before:left-0 before:absolute before:bg-white before:scale-y-0 before:origin-bottom hover:before:scale-y-100 before:z-[0]"
            >
              <span className="z-10 relative">Start a Project</span>
            </Link>
            <Link
              to="//pro.motionwares.com"
              className="inline-block text-center font-semibold relative text-black bg-white hover:text-white border-2 border-black px-6 py-3 before:transition-all before:ease-in-out before:h-full before:w-full before:transform before:top-0 before:left-0 before:absolute before:bg-black before:scale-y-0 before:origin-bottom hover:before:scale-y-100 before:z-[0]"
            >
              <span className="z-10 relative">Join our Team</span>
            </Link>
          </div>
        </div>
      </header>

      <section className="bg-black">
        <div className="container min-h-[100vh] flex flex-col md:flex-row md:flex-wrap pt-40 md:pt-48 text-gray-400">
          <div className="md:w-1/2">
            <h2 className="font-serif text-3xl md:text-5xl font-normal md:leading-tight text-white mb-2">
              Our Services
            </h2>
            <p className="font-normal text-gray-400 mb-6 max-w-[40ch]">
              From Concept to Conversion, we can add value to you. You get our
              team's total commitment to providing you strategic guidance,
              boundless creativity, and a relentlessly agile focus on execution.
            </p>
          </div>
          <div className="md:w-1/4">
            <dl className="md:text-xl">
              <dt className="text-2xl md:text-3xl text-white font-semibold mb-2">
                Design
              </dt>
              <dd className="mb-6">
                <ul className="font-light space-y-2">
                  <li>Product Design</li>
                  <li>UI/UX Design</li>
                  <li>Graphics Design</li>
                  <li>Branding</li>
                  <li>Motion Graphics</li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="md:w-1/4">
            <dl className="md:text-xl">
              <dt className="text-2xl md:text-3xl text-white font-semibold mb-2">
                Development
              </dt>
              <dd className="mb-8">
                <ul className="font-light space-y-2">
                  <li>Product Development</li>
                  <li>Web Development</li>
                  <li>Mobile Development</li>
                  <li>API Development</li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="w-full hidden mt-24 md:mt-32">
            <p className="font-bold text-white text-xl">Trusted by</p>
          </div>
          <div className="w-full hidden grid-cols-2 md:grid-cols-5 pb-32 md:pb-44 pt-6 gap-y-6 md:gap-y-0">
            <figure className="">
              <StaticImage
                src="../images/partners/usaid.svg"
                className="h-[100px] w-[180px] object-contain"
                alt="USAID"
              />
            </figure>
            <figure className="">
              <StaticImage
                src="../images/partners/usaid.svg"
                className="h-[100px] w-[180px] object-contain"
                alt="USAID"
              />
            </figure>
            <figure className="">
              <StaticImage
                src="../images/partners/usaid.svg"
                className="h-[100px] w-[180px] object-contain"
                alt="USAID"
              />
            </figure>
            <figure className="">
              <StaticImage
                src="../images/partners/usaid.svg"
                className="h-[100px] w-[180px] object-contain"
                alt="USAID"
              />
            </figure>
            <figure className="">
              <StaticImage
                src="../images/partners/usaid.svg"
                className="h-[100px] w-[180px] object-contain"
                alt="USAID"
              />
            </figure>
          </div>
        </div>
      </section>

      <div className="h-[1px] bg-dark-400"></div>

    </Layout>
  )
}

export default IndexPage
